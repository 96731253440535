import { Color } from '@pafcloud/style';
import { Colors as Color_default } from './colors.default';

export const Colors = {
  ...Color_default,
  BulletDone: Color.Primitive.Secondary,
  TurnoverPercentage: Color.Primitive.Secondary,
  CancelledIcon: Color.Signal.Attention,
  FallbackIconBackground: Color.Primitive.Primary,
  FallbackIcon: Color.Primitive.PrimaryContrast,
};
